import React, { MouseEvent } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { onImageError } from '@/utils/sentry/onImageError';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
type SocialLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type FooterLink = {
  text?: string;
  link?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
};
type FooterGroup = {
  heading?: string;
  footerLinks?: FooterLink[];
};
type Props = {
  className?: string;
  footerGroups: FooterGroup[];
  socialLinks?: SocialLink[];
  logoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  logoAlt: string;
  logoWidth: number;
  logoLink?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  copyrightLink?: {
    href: string;
    onClick(event: MouseEvent): void;
    target?: '_self' | '_blank';
  };
  copyrightLinkText: string;
  cookieSettingsLinkText: string;
};
export function Footer({
  className,
  footerGroups,
  logoImage,
  logoAlt,
  logoWidth,
  logoLink,
  socialLinks,
  copyrightLink,
  copyrightLinkText,
  cookieSettingsLinkText
}: Props) {
  return <footer className={clsx(className, 'bg-black-100 w-full')} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
			<div className="mx-auto md:max-w-3xl lg:max-w-[1156px] pb-20 md:pt-20 md:px-[30px]">
				{footerGroups?.length > 0 ? <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-[repeat(auto-fit,minmax(min(100%/3,max(100%/4)),1fr))] gap-y-16 pb-14">
						{footerGroups?.map((group, i) => {
          if (footerGroups?.length === 0) return null;
          return <div key={i} className="pr-8">
									<h2 className="text-white text-lg font-medium uppercase font-['Gotham_Condensed'] mb-2">
										{group.heading}
									</h2>
									<ul className="md:grid md:grid-cols-2 md:gap-x-5 lg:block">
										{group.footerLinks?.map((footerLink, i) => {
                return <li key={i} className="mb-1.5">
													<Link href={footerLink.link?.href ?? '#'} onClick={footerLink.link?.onClick} target={footerLink.link?.target} className="text-xs text-white hover:text-gray-900">
														{footerLink.text}
													</Link>
												</li>;
              })}
									</ul>
								</div>;
        })}
					</div> : null}

				<div className="md:hidden">
					<Accordion.Root type="multiple" data-sentry-element="unknown" data-sentry-source-file="Footer.tsx">
						{footerGroups?.map((group, i) => {
            if (footerGroups.length === 0) return null;
            return <Accordion.Item value={'item' + i} key={i} className="border-b border-gray-400">
									<Accordion.Trigger className="group flex items-center justify-between text-white w-full text-left text-lg font-medium uppercase font-['Gotham_Condensed'] px-7 py-4 relative">
										{group.heading}
										<div className="h-[6px] w-3 border-[6px] border-b-0 border-x-transparent border-t-gray-500 transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>
									</Accordion.Trigger>
									<Accordion.Content asChild={true}>
										<ul className="px-14 pt-6 pb-12 space-y-4">
											{group.footerLinks?.map((footerLink, i) => {
                    return <li key={i}>
														<Link href={footerLink.link?.href ?? '#'} onClick={footerLink.link?.onClick} target={footerLink.link?.target} className="text text-white hover:text-gray-900">
															{footerLink.text}
														</Link>
													</li>;
                  })}
										</ul>
									</Accordion.Content>
								</Accordion.Item>;
          })}
					</Accordion.Root>
				</div>

				<div className="lg:flex gap-x-5 w-full items-center mt-14 px-6 md:px-0">
					<Link href={logoLink?.href ?? '#'} onClick={logoLink?.onClick} target={logoLink?.target} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
						{logoImage && <Image src={logoImage.url} alt={logoAlt} width={logoWidth} height={logoWidth / (logoImage.dimensions.width / logoImage.dimensions.height)} onError={onImageError} />}
					</Link>

					<div className="flex-1 mt-8 lg:mt-0">
						<ul className="flex flex-wrap lg:justify-end gap-x-4 gap-y-3 mb-3">
							{socialLinks?.map((socialLink, i) => {
              return <li key={i}>
										<Link href={socialLink.link?.href ?? '#'} onClick={socialLink.link?.onClick} target={socialLink.link?.target} className="text-[12px] text-white hover:text-gray-900">
											{socialLink.text}
										</Link>
									</li>;
            })}
						</ul>
						<div className="text-gray-800 text-[12px] leading-relaxed text-left lg:text-right w-full">
							&copy; Copyright 2003 - {new Date().getFullYear()} BigCommerce Pty. Ltd.
							{copyrightLinkText?.length > 0 ? <span>
									<span className="mx-1">|</span>
									<Link href={copyrightLink?.href ?? '#'} onClick={copyrightLink?.onClick} target={copyrightLink?.target} className="underline hover:text-gray-1100">
										{copyrightLinkText}
									</Link>
								</span> : null}
							{cookieSettingsLinkText?.length > 0 ? <span>
									<span className="mx-1">|</span>
									<Link href={'#'} onClick={e => {
                e.preventDefault();
                window?.OneTrust?.ToggleInfoDisplay();
              }} className="underline hover:text-gray-1100">
										{cookieSettingsLinkText}
									</Link>
								</span> : null}
						</div>
					</div>
				</div>
			</div>
		</footer>;
}